<template>
  <div class="footer-view">
    <div class="footer">
      <div class="logo-box" @click="$router.push('/')">
        <div class="img-box">
          <img
            style="transform: rotate(45deg); width: 60px; height: 60px"
            src="../../assets/images/logo/logo-left.gif"
            alt=""
          />
        </div>
      </div>
      <ul>
        <li>
          <p>新手指南</p>
          <p>
            <router-link tag="a" to="/userProtocol/use-help"
              >使用帮助</router-link
            >
          </p>
          <p>
            <router-link tag="a" to="/userProtocol/conditions"
              >入驻条件</router-link
            >
          </p>
          <p>
            <router-link tag="a" to="/userProtocol/Certification"
              >认证指南</router-link
            >
          </p>
        </li>
        <li>
          <p>平台声明</p>
          <p><router-link to="/userProtocol/protocol">用户协议</router-link></p>
          <p>
            <router-link to="/userProtocol/privacy-policy"
              >隐私政策</router-link
            >
          </p>
          <p>平台公告</p>
        </li>
        <li>
          <p>关于我们</p>
          <p>
            <router-link to="/userProtocol/introduction">易缆网</router-link>
          </p>
          <p>易缆科技</p>
        </li>
        <li>
          <p>友情链接</p>
          <p>
            <a target="_blank" href="http://www.zhaozhouwang.cn/">找轴网</a>
          </p>
          <p>易缆科技</p>
          <p>云采365</p>
          <p>云仓365</p>
        </li>
      </ul>
      <div class="contact-us">
        <div style="display: flex">
          <div v-for="(item, index) in imgUrl" :key="index">
          <el-popover placement="top" width="120" trigger="click">
            <img class="qrcode-img" :src="item.qrcodeUrl" alt="" />
            <div class="qr-code" slot="reference">
              <img :src="item.icon" alt="" />
            </div>
          </el-popover>
          </div>
        </div>
        <div class="prompt">点击图标查看二维码</div>
        <p class="link">商务合作 :  <span style="color: #70AEFF;">李建帅  19133394000</span></p>
      </div>
    </div>
    <div class="filings">
      <div class="police">
        <img class="img" src="@/assets/images/gongwang.png" alt="" />
        <div class="into">冀公网安备13052802001976号</div>
      </div>
      <div class="copyright">
        冀ICP备2023005338号-2
        <span>&nbsp;</span> Copyright©河北易缆网络科技有限公司 版权所有
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      imgUrl: [
        {
          icon: require("../../assets/images/wechat.png"),
          qrcodeUrl: require("../../assets/images/qrcode2.jpg"),
        },
        {
          icon: require("../../assets/images/gongzhonghao.png"),
          qrcodeUrl: require("../../assets/images/qrcode.jpg"),
        },
        {
          icon: require("../../assets/images/douyin.png"),
          qrcodeUrl: require("../../assets/images/qrcode3.png"),
        },
        {
          icon: require("../../assets/images/kuaishou.png"),
          qrcodeUrl: require("../../assets/images/qrcode4.png"),
        },
        {
          icon: require("../../assets/images/weibo.png"),
          qrcodeUrl: require("../../assets/images/qrcode5.jpg"),
        },
        {
          icon: require("../../assets/images/toutiao.png"),
          qrcodeUrl: require("../../assets/images/qrcode6.jpg"),
        },
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
}
.footer-view{
  min-width: 1200px;
  background: #5B5B5B;
}
ul,
li {
  list-style: none;
}
.footer {
  box-sizing: border-box;
  padding: 15px calc((100% - 1200px) / 2);
  display: flex;
  height: 153px;
  justify-content: space-between;
  
  ul {
    width: 700px;
    li {
      height: 31px;
      display: flex;
      align-items: center;
      line-height: 31px;
    }
    li > p {
      font-size: 14px;
      font-weight: 400;
      color: #fff;
      height: 100%;
      width: 134px;
      text-align: center;
      &:first-child {
        font-size: 16px;
        font-weight: 400;
        color: #fff;
      }
      a {
        text-decoration: none;
        color: #fff;
      }
      a:hover{
        color: #409EFF !important;
      }
    }
  }
  .contact-us {
    margin-top: 30px;
    .prompt {
      margin-top: 9px;
      margin-bottom: 23px;
      font-size: 14px;
      color: #fff;
      opacity: 0.7;
    }
    .link {
      margin-top: 14px;
      font-size: 14px;
      color: #ffffff;
    }
    .qr-code {
      width: 24px;
      height: 24px;
      margin-right: 11px;
      img {
        width: 100%;
      }
    }
  }
}
.qrcode-img {
  height: 120px;
  width: 120px;
}
.filings {
  height: 37px;
  display: flex; 
  align-items: center;
  justify-content: center;
  border-top: 1px solid #535353;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  background: #5B5B5B;
}
.police{
  display: flex;
  height: 36px;
  align-items: center;
  margin-right: 60px;
  .img{
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
}
.copyright {
  height: 36px;
  line-height: 36px;
  text-align: center;
  color: #fff;
}
.logo-box {
  display: flex;
  align-items: center;
  cursor: pointer;
  .img-box {
    display: flex;
    align-items: center;
    img {
      &:nth-child(1) {
        width: 80px;
        height: 80px;
      }
    }
  }
  p {
    padding: 0;
    margin: 0;
    margin-top: 6px;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #333333;
  }
}
</style>
