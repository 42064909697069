import Vue from "vue"
import { ACCESS_TOKEN } from "../mutation-types"
import { login, getInfo ,activeAccount} from "@/api/user"
import { removeToken } from '@/utils/auth'
import { EventBus } from '@/utils/vueBus';
const user = {
  namespaced: true,
  state: {
    token: '',
    username: '',
    userInfo: {},
    avatar: require('@/assets/images/头像.png'),
  },
  getters: {
    userInfo(state) {
      return state.userInfo
    },
    isLogin(state) {
      return !!(state.token || Vue.ls.get(ACCESS_TOKEN))
    }
  },
  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_NAME: (state, { username }) => {
      state.username = username
    },
    SET_INFO: (state, userInfo) => {
      state.userInfo = userInfo
      state.username = userInfo.nickName
      state.avatar = userInfo.avatar
    },
  },
  actions: {
    login({ commit }, params) {
      return login(params.type, params.data).then(({ data }) => {
          if(data.code == 0){
            if (!Array.isArray(data.data)) {
              if(data.extras?.actived){
                Vue.ls.set(ACCESS_TOKEN, data.data, 7 * 24 * 60 * 60 * 1000);
                commit('SET_TOKEN', data.data);
              }else{
                commit('SET_TOKEN', data.data);
              } 
              EventBus.$emit('mySocketEvent')
            }
          }
          return data
      })
    },
    activeAccount({ commit }, data){
      return new Promise((resolve, reject) => {
        activeAccount(data).then(({ data }) => {
          if (data.code == '200' || data.code == '0') {
            resolve(data)
          } else {
            reject(data)
          }
        })
      })
    },
    getInfo({ commit }) {
      return new Promise((resolve, reject) => {
        getInfo().then(response => {
          const { data } = response.data
          if (response.data.code !== 0) {
            reject('Verification failed, please Login again.')
          }
          const { name } = data
          commit('SET_INFO', data)
          commit('SET_NAME', { username: name })
          resolve(data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    logout({ commit }) {
      return new Promise((resolve) => {
        commit('SET_INFO', '')
        commit('SET_NAME', '')
        localStorage.removeItem('username')
        localStorage.removeItem('password');
        EventBus.$emit('stopMySocketEvent')
        removeToken()
        resolve()
      })
    },
    // remove token
    resetToken({ commit }) {
      return new Promise(resolve => {
        commit('SET_TOKEN', '')
        removeToken()
        resolve()
      })
    },
  }
}
export default user