<template>
  <div id="app">
    <el-backtop></el-backtop>
    <homeHeader v-if="isLoaded && !$route.meta.isShowHeader"></homeHeader>
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive" />
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive" :key="$route.fullPath" />
  </div>
</template>
<script>
import { Websocket } from "@/mixins/socket";
import homeHeader from "@/components/header/homeHeader.vue";
import { accessTouch } from "@/api/api";
export default {
  mixins:[Websocket],
  components: {
    homeHeader,
  },
  data() {
    return {
      isLoaded: false,
    };
  },
  created() {
    accessTouch().then();
  },
  mounted() {
    this.isLoaded = true;
  },
};
</script>
<style lang="scss">
.el-popover {
  min-width: 0 !important;
}
html,
body {
  // height: 100%;
  background: #f5f5f5;
}
#app {
  font-family: Source Han Sans CN, PingFang SC, Avenir, Helvetica, Arial,
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // font-family: Source Han Sans CN;
  background: #f5f5f5;
  // height: 100%;
  overflow-y: auto;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #e60012 !important;
  border-color: #e60012 !important;
}
// 更改进度条样式
#nprogress .bar {
  background: #e60012 !important;
}
[v-cloak] {
  display: none !important;
}
</style>
<style lang="scss">
// .notice .el-notification.right {
//   padding: 0 !important;
//   width: 300px;
//   box-sizing: border-box;
// }
.el-notification {
  padding: 0 !important;
  width: auto !important;
}
.notice .el-notification__group {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.notice .el-notification__content {
  margin-top: 0 !important;
}
.notice .el-notification__closeBtn {
  top: 10px !important;
}
</style>
