/**
 * 创建唯一的字符串
 * @return {string} ojgdvbvaua40
 */
function createUniqueString() {
  const timestamp = +new Date() + ''
  const randomNum = parseInt((1 + Math.random()) * 65536) + ''
  return (+(randomNum + timestamp)).toString(32)
}

// elementui日期时间范围 快捷选项
const pickerOptionsRange = {
  shortcuts: [
    {
      text: '今天',
      onClick(picker) {
        const end = new Date()
        const start = new Date(new Date().toDateString())
        start.setTime(start.getTime())
        picker.$emit('pick', [start, end])
      }
    }, {
      text: '最近一周',
      onClick(picker) {
        const end = new Date()
        const start = new Date()
        start.setTime(end.getTime() - 3600 * 1000 * 24 * 7)
        picker.$emit('pick', [start, end])
      }
    }, {
      text: '最近一个月',
      onClick(picker) {
        const end = new Date()
        const start = new Date()
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
        picker.$emit('pick', [start, end])
      }
    }, {
      text: '最近三个月',
      onClick(picker) {
        const end = new Date()
        const start = new Date()
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
        picker.$emit('pick', [start, end])
      }
    }
  ]
}
export function throttle(func, delay) {
  var delay = delay || 1000;
  var previousDate = new Date();
  var previous = previousDate.getTime();  // 初始化一个时间，也作为高频率事件判断事件间隔的变量，通过闭包进行保存。
  
  return function(...args) {
      var context = this;
      var nowDate = new Date();
      var now = nowDate.getTime();
      if (now - previous >= delay) {  // 如果本次触发和上次触发的时间间隔超过设定的时间
          func.call(context, ...args);  // 就执行事件处理函数 （eventHandler）
          previous = now;  // 然后将本次的触发时间，作为下次触发事件的参考时间。
      }
  }
}
//防抖
export function debounce(fn, wait=500) {
    let timeout;
    return function (...args) {
        clearTimeout(timeout)
        timeout = setTimeout(() => {
            fn.apply(this, args)
        }, wait);
    }
}
// elementui月份范围 快捷选项
const pickerOptionsRangeMonth = {
  shortcuts: [
    {
      text: '今年至今',
      onClick(picker) {
        const end = new Date()
        const start = new Date(new Date().getFullYear(), 0)
        picker.$emit('pick', [start, end])
      }
    },
    {
      text: '最近半年',
      onClick(picker) {
        const end = new Date()
        const start = new Date()
        start.setMonth(start.getMonth() - 6)
        picker.$emit('pick', [start, end])
      }
    },
    {
      text: '最近一年',
      onClick(picker) {
        const end = new Date()
        const start = new Date()
        start.setMonth(start.getMonth() - 12)
        picker.$emit('pick', [start, end])
      }
    }
  ]
}
//二进制流导出
export function handlerExport(res) {
  let url = window.URL.createObjectURL(res.data);
  let a = document.createElement("a");
  let fileName = res.headers["content-disposition"].split("filename=")[1];
  a.href = url;
  a.download = fileName ? decodeURIComponent(fileName, 'utf-8') : "导出数据_" + new Date().getTime() + ".xlsx"; //自定义导出文件名
  a.click();
}
//js大数字转换，将大额数字转换为万、千万、亿等
export function bigNumberTransform (value) {
  const newValue = ['', '', '']
  let fr = 1000
  let num = 3
  let text1 = ''
  let fm = 1
  while (value / fr >= 1) {
    fr *= 10
    num += 1
    // console.log('数字', value / fr, 'num:', num)
  }
  if (num <= 4) { // 千
    newValue[0] = parseInt(value / 1000) + ''
    newValue[1] = '千'
  } else if (num <= 8) { // 万
    text1 = parseInt(num - 4) / 3 > 1 ? '千万' : '万'
    // tslint:disable-next-line:no-shadowed-variable
    fm = text1 === '万' ? 10000 : 10000000
    if (value % fm === 0) {
      newValue[0] = parseInt(value / fm) + ''
    } else {
      newValue[0] = parseFloat(value / fm).toFixed(2) + ''
    }
    newValue[1] = text1
  } else if (num <= 16) { // 亿
    text1 = (num - 8) / 3 > 1 ? '千亿' : '亿'
    text1 = (num - 8) / 4 > 1 ? '万亿' : text1
    text1 = (num - 8) / 7 > 1 ? '千万亿' : text1
    // tslint:disable-next-line:no-shadowed-variable
    fm = 1
    if (text1 === '亿') {
      fm = 100000000
    } else if (text1 === '千亿') {
      fm = 100000000000
    } else if (text1 === '万亿') {
      fm = 1000000000000
    } else if (text1 === '千万亿') {
      fm = 1000000000000000
    }
    if (value % fm === 0) {
      newValue[0] = parseInt(value / fm) + ''
    } else {
      newValue[0] = parseFloat(value / fm).toFixed(2) + ''
    }
    newValue[1] = text1
  }
  if (value < 1000) {
    newValue[0] = value + ''
    newValue[1] = ''
  }
  return newValue.join('')
}
export default { createUniqueString, pickerOptionsRange, pickerOptionsRangeMonth }


export function groupBy(objectArray, property){
  return objectArray.reduce((acc, cur) => {
    if (acc[cur[property]]){
      acc[cur[property]].push(cur);
    } else {
      acc[cur[property]] = [cur];
    }
    return acc;
  }, {})
}